<template>
  <div id="myPage-editProfile">
    <card-header ref="header" :node="headerTitle" />
    <div id="content-scroll" class="content-scroll">
      <div class="page-editProfile">
        <div class="menu__item">
          <div class="menu__item__content" @click="nextMyPage()">
            <div>
              <span> プロフィール編集</span>
            </div>
            <div class="menu__icon__color">
              <img
                src="~@/assets/image/arrow-right-color.svg"
                alt="arrow-back"
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="menu__item" @click="nextRoundLog()">
          <div class="menu__item__content">
            <div>
              <span> ラウンドログ・評価</span>
            </div>
            <div class="menu__icon__color">
              <img
                src="~@/assets/image/arrow-right-color.svg"
                alt="arrow-back"
              />
            </div>
          </div>
        </div>
        <hr />
        <div class="menu__item" @click="nextPersonalInformation">
          <div class="menu__item__content menu__item__cursor">
            <div>
              <span> 個人情報</span>
            </div>
            <div class="menu__icon__color">
              <img
                src="~@/assets/image/arrow-right-color.svg"
                alt="arrow-back"
              />
            </div>
          </div>
        </div>
        <hr />
        <!-- <div class="menu__item" @click="nextIdentifyProfile">
          <div class="menu__item__content">
            <div>
              <span> 身分証明書の確認(更新)</span>
            </div>
            <div class="menu__icon__color">
              <img
                src="~@/assets/image/arrow-right-color.svg"
                alt="arrow-back"
              />
            </div>
          </div>
        </div>
        <hr /> -->
        <!-- <div class="menu__item" @click.prevent="redirectPage">
          <div class="menu__item__content">
            <div>
              <span> クレジットカード情報登録</span>
            </div>
            <div class="menu__icon__color">
              <img
                src="~@/assets/image/arrow-right-color.svg"
                alt="arrow-back"
              />
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <CardFooter ref="footer" />
  </div>
</template>
<script>
import CardHeader from "@/components/CardHeader";
import CardFooter from "@/components/CardFooter";
import { mapGetters } from "vuex";

export default {
  name: "MyPage-EditProfile",
  components: {
    "card-header": CardHeader,
    CardFooter
  },
  data() {
    return {
      headerTitle: {
        text: "プロフィール編集",
        isShowTitle: true,
        isShowLogo: false
      }
    };
  },
  metaInfo() {
    return {
      title: "プロフィール編集",
      titleTemplate: "%s | PreGo"
    };
  },
  computed: {
    ...mapGetters({
      cardInfo: "payment-stripe/cardInfo"
    })
  },
  async mounted() {
    await this.$store.dispatch("payment-stripe/getCreditCard");
  },
  methods: {
    redirectPage() {
      this.$root.$refs.loading.start();
      if (this.cardInfo !== null) {
        this.$router.push({ name: "InfoCardCredit" });
      } else {
        this.$router.push({ name: "MenRegisterCardCredit" });
      }
      this.$root.$refs.loading.finish();
    },
    nextMyPage() {
      this.$root.$refs.loading.start();
      this.$router.push({ name: "MenMyPageEditProfile" });
      this.$root.$refs.loading.finish();
    },
    nextRoundLog() {
      this.$root.$refs.loading.start();
      this.$router.push({
        name: "MenRoundLogList",
        query: {
          sex: 1
        }
      });
      this.$root.$refs.loading.finish();
    },
    nextIdentifyProfile() {
      this.$root.$refs.loading.start();
      this.$router.push({ name: "MenMyPageIdentifyProfile" });
      this.$root.$refs.loading.finish();
    },
    nextPersonalInformation() {
      this.$root.$refs.loading.start();
      this.$router.push({ name: "MenMyPagePersonalInformationEdit" });
      this.$root.$refs.loading.finish();
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/men/myPage-EditProfile.scss";

.content-scroll {
  // overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 42px;
  min-height: 100vh;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  .content-scroll {
    padding-top: 80px;
    // height: calc(100vh - 152px);
  }
}
</style>
